/* You can add global styles to this file, and also import other style files */
@use './assets/styles/style.scss' as style;
@use './assets/styles/mixin.scss' as mixin;
@use './assets/styles/font.scss' as font;
@import "primeicons/primeicons.css";

html{
  scroll-behavior: smooth;
}
body {
  --font-family: "Segoe UI" !important;
  margin: 0px;
  font-family: font.$base-font;
  color: style.$gray-bold-color;
  h1,h2,h3,h4,h5{
    margin: 0px;
    font-weight: normal;
  }
}

lib-input {
  width: 100%;
  #lib-input {
    width: 100%;
    span {
      width: 100%;
      text-align: center;
      .pi {
        left: 2.75rem;
      }
      input {
        @include mixin.defult-input();
      }
    }
  }
}

p-password,
p-password .p-password {
  width: 100%;
}

p-password,
.p-password-mask {
  max-width: 379px;
  margin: auto !important;

  .p-password {
    width: 100%;

    input {
      @include mixin.defult-input();
    }
    .p-icon-wrapper{
      color: style.$base-color;
    }
  }
}

.p-checkbox-box {
  border-radius: 50%;
}

p-dropdown {
  width: 100%;
  .p-dropdown {
    width: 100%;
    max-width: 379px;
    border-radius: 22px;
    padding-left: 2.5rem;
    .p-dropdown-label {
      padding-left: 0;
    }
    .p-icon-wrapper{
      color: style.$base-color;
    }
  }
  &.ng-dirty.ng-invalid {
    .p-dropdown{
      border-color: #e24c4c;
    }
  }
  
}

.lib-calendar{
  width: 100%;
  span {
    width: 100%;
    text-align: center;
    .pi {
      left: 2.75rem;
    }
    input {
      @include mixin.defult-input();
    }
  }
}



.general-swiper-container{
  padding-left: 30px ;
  padding-top: 30px;
  @media screen and (min-width: 1200px) {
    padding-right: 30px;
}
  swiper-container{
    .swiper-container__wrapper{
        margin-left: 5px;
        margin-right: 5px;
        height: 100%;
    }
    swiper-slide {
      max-width: 390px !important;
      margin-bottom: 10px;
      margin-top: 10px;
      height: auto;
    }
  }
  @media screen and (max-width: 389px) {
      padding-right: 30px;
  }
}

.max-container{
  max-width: 1700px;
  margin: auto !important;
}
.title-lg{
  @include mixin.font($line-height: 60px, $fontWeight: font.$segoe-bold, $color: style.$black-color, $size: clamp(28px, 2.5vw, 42px));    
  @media screen and (max-width: 980px) {
    @include mixin.font($line-height: 30px,$fontWeight: font.$segoe-bold, $color: style.$black-color, $size: clamp(28px, 2.5vw, 42px));    
  } 
}
.title-md, .p-dialog-title{
  @include mixin.font($fontWeight: font.$segoe-bold, $color: style.$black-color, $size: clamp(25px, 1.2vw, 30px));
}
.title-sm{
  @include mixin.font($fontWeight: font.$segoe-semi-bold, $color: style.$black-color, $size: clamp(16px, 0.9vw, 26px));
}
.title-xs{
  @include mixin.font($fontWeight: font.$segoe-semi-bold, $color: style.$black-color, $size: clamp(14px, 0.7vw, 16px));
}
.text-description-light{
  @include mixin.font($fontWeight: font.$segoe-light, $color: style.$gray-bold-light-color, $size: clamp(14px, 0.7vw, 16px));   
}
.text-description{
  @include mixin.font($fontWeight: font.$base-font, $color: style.$gray-bold-light-color, $size: clamp(14px, 0.7vw, 16px));   
}
.subtitle{
  @include mixin.font($fontWeight:font.$base-font, $size: clamp(15px, 1vw, 25px), $color: style.$gray-bold-color);   
}
.text-capitalize{
  text-transform: capitalize;
}

.card-list{
  max-width: 360px;
}
.a-button {
  font-family: font.$segoe-bold;
  color: style.$white-color;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  background: style.$base-color;
  text-decoration: none;
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  padding: 0.75rem 1.25rem;
}

app-tt-card{
  height: 100%;
}
@media screen and (max-width: 980px) {
  .p-dynamic-dialog {
    width: 95vw !important;
  }
}

p-checkbox{
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: style.$base-color;
  }
  .p-checkbox-box:hover {
      border-color: style.$base-color;
  }
  
  .p-checkbox-box.p-highlight:hover {
      border-color: style.$base-color;
      background: style.$base-color;
      color: #ffffff;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
      border-color: style.$base-color;
      background: style.$base-color;
  }
  .p-checkbox-box {
      border-radius: 6px;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      box-shadow:none;
      border-color: style.$base-color;
  }
}