$segoe-light: 300;
$base-font: 500;
$segoe-semi-bold: 600;
$segoe-bold: 700;

$merienda-light: 300;
$merienda-regular: 400;
$merienda-medium: 500;
$merienda-semi-bold: 600;
$merienda-bold: 700;
$merienda-extra-bold: 800;
$merienda-black: 900;
