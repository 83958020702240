@use './font.scss' as font;
@use './style.scss' as style;

@mixin font($size:24px, $line-height:20px, $color:style.$black-color, $fontWeight:font.$base-font, $margin: 0px){
    font-family: 'Poppins', sans-serif;
    font-weight: $fontWeight !important;
    color:$color;
    font-size: $size;
    line-height: $line-height;
    margin: $margin;
}

@mixin default-text(
    $fontWeight:font.$base-font, 
    $color:style.$white-color,
    $size:12px
    ){
    font-family: 'Poppins', sans-serif;
    font-weight: $fontWeight !important;
    color: $color;
    font-size: $size;
}
@mixin default-icon(
    $color:style.$base-color,
    $size:12px
    ){
    color: $color;
    font-size: $size;
}

@mixin focused-inset() {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow .2s;
    box-shadow: inset var(--focus-ring);
}

@mixin default-sidebar(
    $minWidth: 227px,
    $backgroundColor: style.$gray-color
    ){
    min-width: $minWidth;
    background-color: $backgroundColor;
    width: 100%;
}
@mixin defult-input(){
    border-radius: 22px;
    max-width: 379px;
    width: 100%;
}

@mixin default-background-pattern{
        background-image: url(style.$background-pattern);
}

@mixin error(){
    color: style.$base-color;
}

@mixin display-flex($gap:0, $wrap:wrap, $justify-content:center, $align-content:center, $align-items:center){
    display: flex;
    gap: $gap;
    flex-wrap: $wrap;
    justify-content: $justify-content;
    align-content: $align-content;
    align-items: $align-items;
}